<template>
  <!-- 产品三级菜单 -->
  <div class="microbial">
    <!-- 头部 -->
    <swipeTop :top_img="top_img" :ZHtitle="ZHtitle" :ENtitle="ENtitle">
    </swipeTop>
    <!-- 搜索框 -->
    <seachVue @searchName="searchName"></seachVue>
    <!--标签-->
    <classifyList :checkindex="checkindex" :toptype="toptype"></classifyList>
    <!-- 开篇文字 -->
    <template v-if="toptext">
      <div v-if="menuId == 228 || menuId ==187" class="productContent" style="color: white;height: 55.5%;text-indent: 1.5em;margin: 10px;">
        <div v-html="toptext"></div>
<!--        <div>{{toptext}}</div>-->
      </div>
<!--      <div v-else class="OpeningWords">-->
<!--        <span>{{ toptext }}</span>-->
<!--      </div>-->

    </template>


    <!-- 产品列表 -->
    <template>
      <div
          v-for="(item, index) in productList"
          :key="index"
          class="productContent"
          v-if="menuId != 184"
          style="
        height: 55.5%;
        color: white;
        text-indent: 1.5em;
        margin: 10px;
        overflow: scroll;
      "
      ><div>{{ item.description }}</div></div>
      <div v-else style="overflow:scroll;height: 57.9%">
        <div class="headline">
          赛默飞世尔科技
        </div>
        <div class="content">
          <div>
            <p style="text-indent:1.5rem" >赛默飞世尔科技是赋能科技进步的全球领导者。</p>
            <p style="text-indent:1.5rem"> 我们的使命是帮助客户使世界更健康、更清洁、更安全。我们帮助客户加速生命科学领域的研究、解决在分析领域所遇到的复杂问题与挑战、提高实验室生产力、通过提供诊断以及研发制造各类突破性的治疗方法，从而改善患者的健康。</p>
            <p style="text-indent:1.5rem"> 我们全球的员工将借助于一系列行业领先的品牌——Thermo Scientific、Applied
              Biosystems、Invitrogen、Fisher Scientific、Unity Lab Services、Path
              eon和PPD，  为客户提供领先的创新技术、便携采购方案和全方位的制药服务。</p>
          </div>
        </div>
        <div class="itemBox">
          <div class="item">
            <div class="itemTitle">创新引领</div>
            <div class="introduce">以创新促进临床医学的发展，提高医学实验室生产力，引领精准医疗飞速发展是赛默飞不懈的追求。</div>
          </div>
          <div class="item">
            <div class="itemTitle">本土发展</div>
            <div class="introduce">秉持“扎根中国，服务中国”理念，以创新驱动本地化发展为核心，与中国政府、科学界以及应用市场的客户并肩合作。</div>
          </div>
          <div class="item">
            <div class="itemTitle">客户为先</div>
            <div class="introduce">深耕于行业领域，针对行业特点和发展阶段，为客户提供独特的价值主张。</div>
          </div>
        </div>
        <div class="headline">
          临床诊断产品解决方案
        </div>
        <div class="content">
          <div>
            <p style="text-indent:1.5rem" >作为临床诊断实验室，需要能够精准快速的完成高质量的检验工作，从而支持临床决策，为患者提供有效的诊疗服务和疾病管理措施。赛默飞世尔科技始终关注临床诊断实验室的挑战和发展需求，通过创新和完整的产品解决方案助力临床诊断实验室创造更优秀的患者服务和价值。
            </p>
          </div>
        </div>
      </div>
    </template>
    <!-- <div class="listBox" :style="toptext ? 'height:38.9%' : 'height:53.9%'">
      <listVue
        @todetaill="todetaill"
        :listTitle="listTitle"
        :projuctList="productList"
        :labelList="moduleList"
        @chooseMou="chooseMou"
      ></listVue>
    </div> -->
  </div>
</template>
<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "@/components/tag";
import listVue from "@/components/listVue.vue";
import classifyList from "@/components/classifyList.vue";
import { genMenuInfo } from "@/api/path";
export default {
  name: "microbial",
  components: {
    swipeTop,
    listVue,
    seachVue,
    classifyList,
    tag,
  },
  data() {
    return {
      top_img: "", // 头部背景图片
      ZHtitle: "", // 中文标题
      ENtitle: "", // 英文标题
      moduleList: [], // 分类标题
      productList: [], // 列表数据
      correlationId: 0,
      checkindex:
        Number(this.$route.query.checkindex) ||
        Number(this.$route.query.menuId),
      menuId: this.$route.query.menuId,
      toptext: "",
      toptype: [],
      bgcolor: "",
      maxparentId: "", //拿到导航栏的父级id
      tagList: [
        {
          tid: 0,
          text: "公司介绍",
        },
        {
          tid: 1,
          text: "微生物检测",
        },
        {
          tid: 2,
          text: "过敏原检测",
        },
        {
          tid: 3,
          text: "移植诊断",
        },
      ],
    };
  },
  created() {
    this.getAllMenu();
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.menuId;
      this.checkindex = Number(this.$route.query.checkindex);
      this.getAllMenu();
    },
  },
  mounted() {
    // 监听移动端返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  methods: {
    // 返回
    // back() {
    //   this.$router.go(-1);
    // },
    // 获取搜索返回内容
    searchName(res) {
      console.log("搜素框返回内容：", res);
    },
    // 分类选择切换
    chooseMou(item, index) {
      let params = {
        mid: item.menuId,
      };
      genMenuInfo(item.menuId, params).then((res) => {
        this.productList = res.data.productList;
      });
      this.currentSort = index;
    },
    // 获取初始数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.correlationId = res.data.correlationId; // 顶层id
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.top_img = res.data.background; // 获取头部背景图片
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.toptext = res.data.content; // 获取开篇文字
          this.toptype = res.data.classificationMessageAlways; // 获取导航标签
          if (this.maxparentId == 5) {
            this.bgcolor = "#00078c";
            this.listTitle = "活动信息";
          }
          this.moduleList = res.data.subMenuList; // 获取分类
          // 判断是否有分类
          if (this.moduleList == null) {
            // 获取分类下内容
            this.productList = res.data.productList;
          } else {
            this.productList = res.data.subMenuList[0].productList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 详情页
    todetaill(item) {
      if (item.fileType === 4) {
        location.href = item.fileUrl;
      } else {
        this.$router.push({
          path: "/detailsPage",
          query: { menuid: item.productId },
        });
      }
    },
    // 返回事件处理
    backButton() {
      this.$router.push({
        path: "/secondaryPagesPPJS?mid=4",
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>
<style lang="scss" scoped>
.microbial {
  background-color: #8f201c;
  height: 100vh;
  scrollbar-width: none;
}
.OpeningWords {
  background-color: #bf545a;
  color: #fff;
  font-size: 3.2vw;
  line-height: 40px;
  height: 15%;
  /*overflow: hidden;*/
  padding: 20px 20px;
  letter-spacing: 3px;
  text-indent: 58px;
  overflow: auto;
}
.headline{
  margin: 50px 30px 0 30px;
  border-bottom: 1px solid #ffffff;
  line-height: 60px;
  color: white;
  font-weight: 510;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 5px 10px;
 div{
   width: 95%;
   color: white;
   font-size: 0.7rem;
   line-height: 40px;
   border-radius: 10px;
   padding: 5px 25px;
   background-color: #95302f;
 }
}
.itemBox{
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
  .item{
    margin: 0 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    border: 1px solid #ffffff;
    .itemTitle{
      text-align: center;
      font-weight: bolder;
      width: 100%;
      color: #cf3635;
      border-radius: 10px 10px 0 0;
      background-color: white;
    }
    .introduce{
      font-size: 0.6rem;
      padding: 10px 20px;
    }
  }

}
.productContent{
  div{
    background-color: #95302f;
    font-size: 0.7rem;
    padding: 35px;
    border-radius: 10px;
    line-height: 40px;
  }
}
/deep/.van-search__content {
  background-color: #f7f8fa !important;
}
/deep/ .listTitle {
  span {
    color: #fff;
  }
}
.tag {
  background-color: #fff;
}
</style>
